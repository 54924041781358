import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Haushaltsversicherung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Haushaltsversicherung – Die Basics im Überblick" showCalc={false} />
            <Article>
                <p>
                    Von einem plötzlich aufgezogenen Sturm zerschlagene Fensterscheiben, durch ein Erdbeben umgestürzte
                    Regale, die im Fallen die Vorhänge mitreißen, wodurch sich die Vorhangstange löst und auf den
                    gläsernen Esstisch stürzt… Die Gründe, die für eine Haushaltsversicherung sprechen, sind viel
                    naheliegender als das skizzierte Szenario. Auch kleine Missgeschicke im Haushalts können schließlich
                    zu großen Schäden führen. Welche Leistungen eine Haushaltsversicherung umfasst und was sie in etwa
                    kostet, erfährst du im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Haushaltsversicherung?</h2>
                <p>
                    Wichtig zu wissen ist zunächst, dass sich eine Haushaltsversicherung aus zwei wesentlichen
                    Komponenten zusammensetzt: Der Haftpflichtversicherung und der Haushaltsversicherung im engeren
                    Sinn. Während erstere greift, wenn du und andere Bewohner:innen des Haushalts Gegenstände anderer
                    Personen beschädigen, schützt eine Haushaltsversicherung dich vor den Kosten von Schäden, die Dritte
                    an deinen Dingen verursacht haben.
                </p>
                <hr />

                <h2>Was beinhaltet eine Haushaltsversicherung</h2>
                <p>
                    Eine Haushaltsversicherung kann sich nicht nur auf Schäden beziehen, die an deiner Immobilie oder
                    der Einrichtung durch Dritte verschuldet werden. Mittlerweile lassen sich Haushaltsversicherungen
                    auch auf Geräte ausdehnen, sodass du etwa Handys, Tablets, Küchen- oder Sportgeräte mit einbeziehen
                    kannst. Darüber hinaus ist es seit mehreren Jahren auch möglich, sich im Rahmen einer
                    Haushaltsversicherung vor Cyberkriminalität zu schützen und durch entsprechende Erweiterungen
                    persönliche Daten zu besichern. Für Schäden, die bereits im Zuge des{" "}
                    <Link to="/artikel/hausbau-kosten/" target="_blank" rel="noreferrer noopener">
                        Hausbaus
                    </Link>{" "}
                    entstehen, gibt es die Gebäudeversicherung. Im Internet findest du zahlreiche Vergleichsportale, mit
                    deren Hilfe du dir selbst einen Überblick über die verschiedene Anbieter, Leistungen und Kosten von
                    Haushaltsversicherungen verschaffen kannst. Das funktioniert so einfach wie bei unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <hr />

                <h2>Was kostet eine Haushaltsversicherung?</h2>
                <p>
                    Wie gerade deutlich wurde, können im Rahmen einer Haushaltsversicherung je nach Wohnsituation und
                    persönlichem Sicherheitsbedürfnis ganz unterschiedliche Schadensfälle abgedeckt werden. Selbst ein
                    und dieselbe Immobilie kann, von unterschiedlichen Personen bewohnt, mit stark variierenden
                    Leistungen besichert werden und daher gänzlich unterschiedliche Versicherungskosten erzeugen.
                    Wichtig ist es in jedem Fall zu wissen, ob dir eine herkömmliche Basisdeckung, die dich etwa gegen
                    Wasser- und Brandschäden schützt, ausreicht oder ob du über größere Vermögenswerte wie
                    Kunstobjekten, teure Musikinstrumente oder andere Geräte verfügst, die nach einem breiteren
                    Deckungsrahmen verlangen. Achte allerdings umgekehrt darauf, bei der Haushaltsversicherung keine
                    unnötigen Leistungen in Anspruch zu nehmen. Gerade wenn man sich vor kurzem mit einem{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    für die{" "}
                    <Link to="/artikel/wohnungsfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Wohnungs-
                    </Link>{" "}
                    oder{" "}
                    <Link to="/artikel/hausfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Hausfinanzierung
                    </Link>{" "}
                    belastet hat, ist eine Übersicherung ganz besonders schmerzhaft. Umfassende und persönliche Beratung
                    ist auch hier das Allerwichtigste.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"haushaltsversicherung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"haushaltsversicherung"}
                heading={"Haushaltsversicherung – Die Basics im Überblick"}
            />
        </Layout>
    );
};

export default Haushaltsversicherung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.haushaltsversicherung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
